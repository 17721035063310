<template>
  <tr>
    <th :class="[$style.td]" v-if="showSelect" />
    <th :class="[$style.td, $style[value]]" v-for="{ value, alias, options, multiple = true } in items" :key="value">
      <div :class="$style.input">
        <div :class="$style.inner">
          <template v-if="options">
            <SelectMultiple
              v-if="multiple"
              :items="options"
              :value="$route.query[alias || value]"
              @input="(val) => onInput({
                key: alias || value,
                value: val,
              })"
            />
            <SelectSingle
              v-else
              :items="options"
              :value="$route.query[alias || value]"
              @input="(val) => onInput({
                key: alias || value,
                value: val,
              })"
            />
          </template>
          <VTextField
              v-else
              dense
              clearable
              hide-details
              :value="$route.query[alias || value]"
              @input="(val) => onInput({
                key: alias || value,
                value: val,
              })"
          />
        </div>
      </div>
    </th>
  </tr>
</template>

<script>
import SelectMultiple from './components/SelectMultiple';
import SelectSingle from './components/SelectSingle';
export default {
  name: 'FilterRow',
  components: {
    SelectMultiple,
    SelectSingle,
  },
  props: {
    items: { type: Array },
    showSelect: { type: Boolean },
  },
  methods: {
    onInput({ key, value}) {
      this.$emit('input', { [key]: value })
    }
  }
}
</script>

<style module lang="scss">
.td {
  width: 110px;
  top: 54px !important;
  &.id { width: 50px}
  &.date { width: 80px }
  &.inspection { width: 130px }
  &.status { width: 100px }
  &.type { width: 220px }
  &.product { width: 180px }

  .input {
    position: relative;
    height: 26px;
    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
</style>
